import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import LoggedInUserDetailsProvider from './context/loggedInUserDetailsProvider';
import PageContextProvider from './context/pageContextProvider';

import App from './App';
import { init } from './services/logService';
import reportWebVitals from './reportWebVitals';

init();

ReactDOM.render(
  <DndProvider backend={HTML5Backend}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <LoggedInUserDetailsProvider>
        <PageContextProvider>
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <App />
            </StyledEngineProvider>
          </BrowserRouter>
        </PageContextProvider>
      </LoggedInUserDetailsProvider>
    </LocalizationProvider>
  </DndProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
