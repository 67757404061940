import React, { useState } from 'react';

import LoggedInUserDetailsContext from './loggedInUserDetails.context';

const LoggedInUserDetailsProvider = ({ children }) => {
  const updateLoggedInUserDetails = updatedDetails => {
    setDetails(currentDetails => {
      return {
        ...currentDetails,
        ...updatedDetails
      };
    });
  };

  const logout = () => {
    setDetails(currentDetails => {
      return {
        ...currentDetails,
        landingPageUrl: '',
        profilePageUrl: '',
        currentUser: {},
        isLoggedIn: false
      };
    });
  };

  const [details, setDetails] = useState({
    landingPageUrl: '',
    profilePageUrl: '',
    currentUser: {},
    isLoggedIn: false,
    updateLoggedInUserDetails,
    logout
  });

  return <LoggedInUserDetailsContext.Provider value={details}>{children}</LoggedInUserDetailsContext.Provider>;
};

export default LoggedInUserDetailsProvider;
