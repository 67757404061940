import { createContext } from 'react';

const PageContextContext = createContext({
  headerComponents: [],
  portalButton: null,
  pageKey: 'none',
  dimensions: {},
  pageSize: {},
  setPageContext: contextProps => {}
});

export default PageContextContext;
