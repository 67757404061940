import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import ReactGA from 'react-ga4';
import './styles/sortableTreeStyle.css';

import ProgressCircle from './components/common/progressCircle';

const LayoutMaster = lazy(() => import('./routes/components/LayoutMaster'));
const HomePage = lazy(() => import('./pages/frontPages/homePage'));
const LoginForm = lazy(() => import('./pages/auth/loginForm'));
const SetPasswordForm = lazy(() => import('./pages/auth/setPasswordForm'));
const ForgotPasswordForm = lazy(() => import('./pages/auth/forgotPasswordForm'));
const ResetPasswordForm = lazy(() => import('./pages/auth/resetPasswordForm'));
const VerifyEmail = lazy(() => import('./pages/auth/verifyEmail'));
const ErrorPage = lazy(() => import('./pages/frontPages/ErrorPage'));
const HttpPostErrorPage = lazy(() => import('./pages/frontPages/HttpPostErrorPage'));
const TestPage = lazy(() => import('./pages/frontPages/TestPage'));
const PrivacyPage = lazy(() => import('./pages/frontPages/PrivacyPage'));
const Sitemap = lazy(() => import('./sitemap/sitemap'));
const AdminPortal = lazy(() => import('./pages/adminPortal/adminPortal'));
const ClientPortal = lazy(() => import('./pages/clientPortal/clientPortal'));
const NotFound = lazy(() => import('./pages/frontPages/notFound'));

ReactGA.initialize('G-E39VV1TWP1');

const App = () => {
  return (
    <Suspense fallback={<ProgressCircle />}>
      <Routes>
        <Route path="/" element={<LayoutMaster />}>
          <Route index element={<HomePage />} />
          <Route path="log-in" element={<LoginForm />} />
          <Route path="set-password" element={<SetPasswordForm />} />
          <Route path="forgot-password" element={<ForgotPasswordForm />} />
          <Route path="reset-password" element={<ResetPasswordForm />} />
          <Route path="verify-email" element={<VerifyEmail />} />
          <Route path="error" element={<ErrorPage />} />
          <Route path="http-post-error" element={<HttpPostErrorPage />} />

          <Route path="test" element={<TestPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="sitemap.xml" element={<Sitemap />} />

          <Route path="admin/*" element={<AdminPortal />} />
          <Route path="client/*" element={<ClientPortal />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
