import { createContext } from 'react';

const LoggedInUserDetailsContext = createContext({
  landingPageUrl: '',
  profilePageUrl: '',
  currentUser: {},
  isLoggedIn: false,
  updateLoggedInUserDetails: updatedDetails => {},
  logout: () => {}
});

export default LoggedInUserDetailsContext;
