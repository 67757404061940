import React, { useState } from 'react';

import PageContextContext from './pageContext.context';

const PageContextProvider = ({ children }) => {
  const setPageContext = updatedDetails => {
    setDetails(currentDetails => {
      return {
        ...currentDetails,
        ...updatedDetails
      };
    });
  };

  const [details, setDetails] = useState({
    headerComponents: [],
    portalButton: null,
    pageKey: 'none',
    dimensions: {},
    pageSize: {},
    setPageContext
  });

  return <PageContextContext.Provider value={details}>{children}</PageContextContext.Provider>;
};

export default PageContextProvider;
