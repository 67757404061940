import React from 'react';
import PropTypes from 'prop-types';
import { Modal, CircularProgress, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useCircularProgressStyles = makeStyles(theme => ({
  root: {
    display: 'block'
  }
}));

const ProgressCircle = ({ show = true, fullScreen = true }) => {
  const circularProgressStyle = useCircularProgressStyles();

  const content = (
    <Box
      sx={{
        p: 2,
        backgroundColor: '#fff',
        border: 'none',
        borderRadius: '50%',
        outline: 'none',
        boxShadow: 3
      }}
    >
      <CircularProgress classes={{ root: circularProgressStyle.root }} color="secondary" />
    </Box>
  );

  return (
    <>
      {fullScreen ? (
        <Modal
          open={show}
          hideBackdrop={true}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', outline: 'none' }}
        >
          {content}
        </Modal>
      ) : (
        <Box
          sx={{
            display: show ? 'block' : 'none',
            position: 'absolute',
            top: 20,
            left: 'calc(50% - 35px)',
            outline: 'none',
            zIndex: 1400
          }}
        >
          {content}
        </Box>
      )}
    </>
  );
};

ProgressCircle.propTypes = {
  show: PropTypes.bool,
  fullScreen: PropTypes.bool
};

export default ProgressCircle;
